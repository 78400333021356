<template>
  <div id="visi-misi-page">
    <div
      class="banner cvr-bg"
      style="background-image: url('/img/dummy/about/vimi/1.jpg');"
    >
      <div class="wrapper">
        <h1>{{ title }}</h1>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of banner -->

    <div class="inner-sidebar">
      <div class="wrapper rowflex">
        <sidebar-about></sidebar-about>
        <article class="main wider">
          <div class="inner-vimi">
            <div
              class="item cvr-bg"
              style="background-image: url('/img/dummy/about/vimi/2.jpg');"
            >
              <h2>
                <span>{{ $t("aboutUs.Vision") }}</span>
              </h2>
              <div class="format-text">
                <p>
                  <b>
                    <i>{{ description.vision.value }}</i>
                  </b>
                </p>
              </div>
              <!-- end of format text -->
            </div>
            <!-- end of item -->
            <div
              class="item cvr-bg"
              style="background-image: url('/img/dummy/about/vimi/3.jpg');"
            >
              <h2>
                <span>{{ $t("aboutUs.Mission") }}</span>
              </h2>
              <div class="format-text">
                <ol>
                  <li
                    v-for="(mission, index) in description.mission.contents"
                    :key="index"
                  >
                    {{ mission.value }}
                  </li>
                </ol>
              </div>
              <!-- end of format text -->
            </div>
            <!-- end of item -->
          </div>
          <!-- end of vimi -->
        </article>
        <!-- end of inner waw -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner aside -->
  </div>
</template>

<script>
import SidebarAbout from "./SidebarAbout";
export default {
  name: "VisiMisi",
  metaInfo: {
    title: "Vision & Mission"
  },
  components: {
    SidebarAbout
  },
  data() {
    return {
      menuId: 7,
      title: null,
      banner: null,
      description: {
        vision: null,
        mission: []
      }
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.$axios
        .get(`/about-us/${this.menuId}`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          if (response.data.success) {
            this.title = response.data.data.title;
            this.banner = response.data.data.banner;
            this.description = response.data.data.descriptions;
          }
        });
    }
  }
};
</script>
